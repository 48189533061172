<template>
  <div id="login-div">
    <div class="title-holder">
      <span> Fluke </span>
      {{ $t("pages.login.title") }} <br />
    </div>
    <div class="login-card">
      <div v-if="!success">
        {{ $t('pages.forgot.title') }} <br />
        <br />
        <v-form v-model="isValid" ref="form">
          <v-text-field
            outlined
            :rules="[requiredRule, emailRule]"
            :label="$t('labels.formEmail')"
            v-model="email"
          />
        </v-form>
      </div>
      <div v-else>
        {{ $t('pages.forgot.disclaimer') }}
        <br />
        <br />
        <v-btn large depressed block color="grey" @click="cancel">
          {{ $t('labels.btnCancel') }}
        </v-btn>
        <br />
        <br />
      </div>
    </div>
    <div class="btn-aligner" v-if="!success">
      <div class="btns-aux">
        <span @click="cancel" class="forgot-link"> {{ $t('labels.btnBack') }} </span>
      </div>
      <div class="btns-main">
        <v-btn large depressed block color="primary" @click="reset">
          {{ $t('labels.btnSend') }}
        </v-btn>
      </div>
    </div>

    <f-language-selector />
  </div>
</template>

<script>
import validatorRules from "../../helpers/validator_rules";
import FLanguageSelector from "./LanguageSelector.vue";

export default {
  components: {
    FLanguageSelector,
  },
  data: () => ({
    email: "",
    success: false,
    isValid: false,
  }),
  computed: {
    requiredRule() {
      return validatorRules.required(this.$t("validation.required"));
    },
    emailRule() {
      return validatorRules.email(this.$t("validation.email"));
    },
  },
  methods: {
    async reset() {
      this.$refs.form.validate();
      if (!this.isValid) return false;

      const response = await this.$store.dispatch(
        "auth/resetPassword",
        this.email
      );
      this.success = true;
    },
    async cancel() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss">
#login-div {
  width: 480px;
  margin-bottom: 100px;

  .login-card {
    background-color: #fff;
    padding: 40px 40px 10px 40px;
    border-radius: 3px;
    margin-bottom: 30px;
  }

  .title-holder {
    padding: 0 40px;
    margin-bottom: 30px;

    span {
      display: block;
      font-weight: 700;
      font-size: 32px;
      margin-bottom: -10px;
    }
  }

  .btn-aligner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;

    .btns-main {
      button {
        width: 150px;
      }
    }
    .btns-aux {
      .forgot-link {
        text-decoration: none;
        color: #a9a9a9;
        cursor: pointer;
      }
    }
  }

  .err {
    background-color: #ff5b5b;
    color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    max-height: 0;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      max-height: 100px;
      padding: 10px 15px;
      opacity: 1;
    }
  }

  .forgot {
    margin-top: 35px;
    font-size: 13px;
    cursor: pointer;
  }
}
</style>
